import React from 'react';
import { Box, Typography, Card, CardContent, CardMedia, Link } from '@mui/material';

const PictureTextCard = ({ image, name, description, link }) => {
  return (
    <Link href={link} target="_blank" underline="none" sx={{ textDecoration: 'none' }}>
      <Card sx={{ display: 'flex', alignItems: 'center', margin: '30px', textDecoration: 'none' }}>
        {/* This is card images */}
        <Box sx={{ width: '50%' }}>
          <CardMedia
            component="img"
            sx={{ height: '300px', width: '500px', objectFit: 'contain' }}
            image={image}
            alt={name}
          />
        </Box>
        {/* This is card text */}
        <Box sx={{ width: '50%', padding: '20px' }}>
          <CardContent>
            <Typography component="div" variant="h5" gutterBottom>
              {name}
            </Typography>
            <Typography variant="body1" color="text.secondary" component="div">
              {description}
            </Typography>
          </CardContent>
        </Box>
      </Card>
    </Link>
  );
};

export default PictureTextCard;

// SingleEvent.js
import React, { useState } from "react";
import '../../App.css';
import ArticleCard from "../ArticleCard";
import MUICard2 from "../MUICard2";
import { Box } from "@mui/material";
import MUISingleEventCard from "../MUISingleEventCard";

function SingleEvent5() {

    const [heroSectionEventData] = useState({
        image: "images/Georgian-Music/2024-2025/gm_13-04-25.jpg",
        title: 'The World of Yesterday',
        subtitle: 'Piano Duo',
        date: 'Sunday 13 April 2025 3.00pm ',
        location: 'Bethel Community Church, 128 Vincent St., Barrie',
        buttonText: 'Book Tickets',
         buttonLink: 'https://www.showpass.com/bca-georgian-music-sun-series-2024-2025/'
    });

    const [eventData] = useState({
        program: `The World of Yesterday: Inspired by Stefan Zweig's memoirs, "The World of Yesterday" is a captivating musical program celebrating Vienna's rich musical history from the 18th to the 20th century. This performance features both piano solo and one piano four-hands pieces, including exciting transcriptions of Johann Strauss's "The Beautiful Blue Danube" and "Fledermaus," and Ravel's "La Valse" arranged for one piano four hands. The program also highlights the timeless works of Schubert, Mozart, and Alban Berg, transporting audiences to a forgotten time with their enchanting melodies. Join us for an unforgettable journey through Vienna's golden era.`,
        performers: `Marcus Scholtes (violin): Marcus Willem Scholtes is currently 
        the Associate Concertmaster of Sinfonia Toronto, a Juno-award-winning ensemble. 
        He is an alumnus of the prestigious Jacobs School of Music at Indiana University, 
        where he graduated with a Doctor of Music degree in Violin Performance in the studio of 
        renowned pedagogue Mimi Zweig. Miriam Stewart-Kroeker(cello): Miriam Stewart-Kroeker is 
        a native of Hamilton, Ontario, where she began her studies at the age of 5.  
        She studied with Paul Pulford and the Penderecki String Quartet at Wilfrid Laurier University, 
        where she received an Honours Bachelor of Music in Cello Performance as well as a Diploma in 
        Chamber Music Performance. `,
        performerLink: '#',
        ticketInfo: `Purchase your season subscription early. 5 amazing upcoming concerts. Adults Live-streaming: Available at the time of your choosing for up to 30 days after the concert - $90. Adults In-person: - $130. Students: $25 either in-person or live-streamed. 
        Single Tickets: These will be made available for each concert starting about 30 days before the event.`,

    });

    const georgianMusic = [
        {
            title: 'Andromeda Trio',
            description: 'Tchaikovsky, Shostakovich, Haydn',
            imageUrl: 'images/Georgian-Music/2024-2025/gm_15-09-24.jpg',
            link: '/SingleEvent'
        },
        {
            title: 'Ishay Shaer',
            description: 'Bach, Beethoven, Debussy, Schumann',
            imageUrl: 'images/Georgian-Music/2024-2025/gm_03-11-24.jpg',
            link: '/SingleEvent2'
        },
        {
            title: 'Venuti Quartet',
            description: 'Indigo',
            imageUrl: 'images/Georgian-Music/2024-2025/gm_15-12-24.jpg',
            link: '/SingleEvent3'
        }
    ];
    
    

    return (
        <Box> 
            <MUISingleEventCard
                image={heroSectionEventData.image}
                title={heroSectionEventData.title}
                subtitle={heroSectionEventData.subtitle}
                date={heroSectionEventData.date}
                location={heroSectionEventData.location}
                buttonText={heroSectionEventData.buttonText}
                buttonLink={heroSectionEventData.buttonLink}
            />
            <ArticleCard 
                title={eventData.title}
                subtitle={eventData.subtitle}
                date={eventData.date}
                location={eventData.location}
                program={eventData.program}
                performers={eventData.performers}
                performerLink={eventData.performerLink}
                ticketInfo={eventData.ticketInfo}
            />
            <Box marginY='30px'>
                <MUICard2 title="2024/25 Georgian Music" events={georgianMusic} buttonLink="/GeorgianMusic" />
            </Box>
        </Box>
    );
}

export default SingleEvent5;

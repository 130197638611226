import React from "react";
import '../../App.css';
import HeroSectionEventCard from '../HeroSectionEventCard';
import { Box } from '@mui/material';
import FounderCard from '../FounderCard';
import PictureTextCard from '../PictureTextCard';

function About() {
  const eventData = {
    backgroundImage: '/images/bg-1.jpg',
    heading: 'About Barrie Concert Association',
    description: 'Bringing World-Class Music to Barrie'
  };

  const founderData = {
    image: '../../images/BurceOwen.png', 
    name: 'Bruce Owen',
    description: 'Monday, February 7, 2022, at the age of 90. Bruce practiced law in Barrie for 63 years, specializing in wills, estates, and real estate law, and served as a small claims court judge. He was a community leader, president of the Chamber of Commerce, Kiwanis Club, and other associations, and helped bring key developments to Barrie, including Centennial Park, Kempenfest, and the current RVH building. Bruce was honored with the Governor General’s Caring Canadian Award and the June Callwood Leadership Award. He is predeceased by his wife Anne (2018) and his brother Doug. Survived by his children Trevor (Heather), Valerie Owen, and Pamela Dubourdieu (Ron), grandchildren Brianna, Chad, Ben, Alexandra, Jonathan, and great-grandchildren Alice and Charlotte. In 2023, Bruce was posthumously awarded the Cultural Changemaker award by the City of Barrie.'
  };

  const churchData = [
    {
      image: '../../images/Logo/Highway-Church.jpg',
      name: 'Highway Church',
      description: '50 Anne St N, Barrie, ON L4N 2B6',
      link:'https://www.hiway.church/'
    },
    {
      image: '../../images/Logo/Bethel-Community-Church.png', 
      name: 'Bethel Community Church',
      description: '128 Vincent St., Barrie, ON L4M 3Y9',
      link:'https://bethelbarrie.ca/'
    }
  ];

  return (
    <Box sx={{backgroundColor: '#F1F1F1'}}>
      <HeroSectionEventCard
        backgroundImage={eventData.backgroundImage}
        heading={eventData.heading}
        description={eventData.description}
      />
      <Box sx={{padding: 5}}>
      <p>The Barrie Concert Association (BCA) is a non-profit organization dedicated to bringing high-quality musical performances to the Barrie community. Founded with the mission to enrich the cultural landscape of the area, the BCA organizes a series of concerts each season, featuring a diverse range of musical genres including classical, jazz, and contemporary. The association aims to make live music accessible to everyone, fostering a deeper appreciation for the arts.</p>
      </Box>
      
      <FounderCard
        image={founderData.image}
        name={founderData.name}
        description={founderData.description}
      />
      <PictureTextCard 
        image={churchData[0].image}
        name={churchData[0].name}
        description={churchData[0].description}
        link={churchData[0].link}
      />
      <PictureTextCard 
        image={churchData[1].image}
        name={churchData[1].name}
        description={churchData[1].description}
        link={churchData[1].link}
      />
    </Box>
  );
}

export default About;

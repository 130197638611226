import React, { useState } from "react";
import '../../App.css';
import ArticleCard from "../ArticleCard";
import MUICard2 from "../MUICard2";
import { Box } from "@mui/material";
import MUISingleEventCard from "../MUISingleEventCard";


function SingleEvent() {

    const [heroSectionEventData] = useState({
        image: "images/Georgian-Music/2024-2025/gm_15-09-24.jpg",
        title: 'Things Lived & Dreamt',
        subtitle: 'Andromeda Trio',
        date: 'Sunday 15 September 2024 3.00pm ',
        location: 'Bethel Community Church, 128 St. Vincent St., Barrie',
        buttonText: 'Book Tickets',
        buttonLink: 'https://www.showpass.com/andromeda-trio/'
    });

    const [eventData] = useState({
        program: `Andromeda Trio was established in 2014 in Waterloo, Ontario.  Since then, they have performed for many chamber music series in the greater Toronto area including for the Kitchener-Waterloo Chamber Music Society, Guelph Connections Concert Series, Laurier Noon Hour Series, Toronto Chamber Players, Conrad Grebel Noon Hour Series and more.`,
        performers: `Marcus Scholtes (violin): Marcus Willem Scholtes is currently the Associate Concertmaster of Sinfonia Toronto, a Juno-award-winning ensemble. He is an alumnus of the prestigious Jacobs School of Music at Indiana University, where he graduated with a Doctor of Music degree in Violin Performance in the studio of renowned pedagogue Mimi Zweig. Miriam Stewart-Kroeker
(cello): Miriam Stewart-Kroeker is a native of Hamilton, Ontario, where she began her studies at the age of 5.  She studied with Paul Pulford and the Penderecki String Quartet at Wilfrid Laurier University, where she received an Honours Bachelor of Music in Cello Performance as well as a Diploma in Chamber Music Performance.  `,
        performerLink: 'https://www.andromedatrio.com/',
        ticketInfo: `Purchase your season subscription early. 5 amazing upcoming concerts. Adults Live-streaming: Available at the time of your choosing for up to 30 days after the concert - $90. Adults In-person: - $130. Students: $25 either in-person or live-streamed. 
        Single Tickets: These will be made available for each concert starting about 30 days before the event.`,

    });

    const georgianMusic = [
        {
            title: 'Ishay Shaer',
            description: 'Bach, Beethoven, Debussy, Schumann',
            imageUrl: 'images/Georgian-Music/2024-2025/gm_03-11-24.jpg',
            link: '/SingleEvent2'
        },
        {
            title: 'Venuti Quartet',
            description: 'Indigo',
            imageUrl: 'images/Georgian-Music/2024-2025/gm_15-12-24.jpg',
            link: '/SingleEvent3'
        },
        {
            title: 'Hall & Farley',
            description: 'A Nightingale Sang',
            imageUrl: 'images/Georgian-Music/2024-2025/gm_02-02-25.jpg',
            link: '/SingleEvent4'
        }
    ];
    

    return (
        <Box> 
            <MUISingleEventCard
                image={heroSectionEventData.image}
                title={heroSectionEventData.title}
                subtitle={heroSectionEventData.subtitle}
                date={heroSectionEventData.date}
                location={heroSectionEventData.location}
                buttonText={heroSectionEventData.buttonText}
                buttonLink={heroSectionEventData.buttonLink} 
            />
            <ArticleCard 
                title={eventData.title}
                subtitle={eventData.subtitle}
                date={eventData.date}
                location={eventData.location}
                program={eventData.program}
                performers={eventData.performers}
                performerLink={eventData.performerLink}
                ticketInfo={eventData.ticketInfo}
                performance={eventData.performance}
            />
            <Box marginY='30px'>
                <MUICard2 title="2024/25 Georgian Music" events={georgianMusic} buttonLink="/GeorgianMusic" />
            </Box>
        </Box>
    );
}

export default SingleEvent;

import React, { useState } from 'react'
import { AppBar, Tab,Tabs,Toolbar, Button, useMediaQuery, useTheme, Typography, Box} from '@mui/material';
import { Link } from 'react-router-dom';
import DrawerComp from "./Drawer";
import MUIButton from './MUIButton';

const MUInavbar = () => {

    const [value, setValue] = useState();
    const theme = useTheme();
    console.log(theme);
    const isMatch = useMediaQuery(theme.breakpoints.down("md"));
    console.log(isMatch);


  return (
    <React.Fragment>
        <AppBar sx={{background: "#000000"}}>
            <Toolbar>
                 {
                    isMatch ? (
                        <>
                         <Box sx={{ display: 'flex', alignItems: 'center' }}>
                         <img src="/images/BCA-logo.jpg" alt="Logo" style={{ height: "30px", marginRight: "5px" }} />
                            <Typography sx={{ fontSize: "1rem", paddingLeft: "5%",  color: 'white' }}>
                                 Barrie Concert Association
                            </Typography>
                            </Box>
                            <DrawerComp />
                        </>
                    ) : (
                        <>
                         <Box sx={{ display: 'flex', alignItems: 'center'}}>
                         <img src="/images/BCA-logo.jpg" alt="Logo" style={{ height: "30px", marginRight: "5px" }} />
                            <Typography sx={{ fontSize: "1rem", paddingLeft: "5%", color: 'white'}}>
                                 Barrie Concert Association
                            </Typography>
                            </Box>
                            <Tabs 
                                sx={{marginLeft: 'auto', '.MuiTab-root': { color: '#999999' },  '.Mui-selected': { color: 'white' }}} 
                                value={value} 
                                onChange={(e, value)=> setValue(value) } 
                                TabIndicatorProps={{
                                    style: { backgroundColor: '#d2a01d' } 
                                }}>
                            <Tab label="Home" component={Link} to="/" />
                            <Tab label="Events" component={Link} to="/Events"/>
                            <Tab label="News" component={Link} to="/News"/>
                            <Tab label="About" component={Link} to="/About"/>
                            <Tab label="Support Us" component={Link} to="/SupportUs" />
                        </Tabs>
                        <a href='https://www.showpass.com/barrie-concerts-and-georgian-music-2024-2025/' target='_blank'>
                        <MUIButton variant="primary" >Order Now</MUIButton>
                        </a>
                      </>
                    )
                 }
            </Toolbar>

        </AppBar>
    </React.Fragment>
  )
}

export default MUInavbar

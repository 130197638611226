// News.js
import React from "react";
import '../../App.css';
import HeroSectionEventCard from "../HeroSectionEventCard";
import PictureTextCard from "../PictureTextCard";
import { Box } from '@mui/material';

function News() {
  const eventData = {
    backgroundImage: '/images/bg-1.jpg',
    heading: 'News & Interviews',
    description: 'Stay updated with the latest news and exclusive interviews from Barrie Concert Association, highlighting upcoming performances and artist insights'
};




const newsData = [
 { 
  image: 'images/News/bc_30-09-23_lgabout.jpg',
  name: "Reviving the Roaring Twenties with Masterful Musicianship and Jazz Improvisation",
  description: "Spirit '20'",
  link:'/News1'
  
},
{ image: 'images/News/gm_04-02-24_lgabout.jpg',
  name: 'Experience the Unique Sound of Ladom Ensemble at Bethel Community Church',
  description: 'Ladom Ensemble',
  link:'/News2'
},
{ image: 'images/News/Announcement.jpg',
  name: 'Exciting News! Our New Website is Live!',
  description: 'Discover a New Way to Experience Music with Our Fresh Online Platform!',
  link:'/News3'
},

];

  return (
    <Box sx={{backgroundColor: '#F1F1F1'}}>
        <HeroSectionEventCard
        backgroundImage={eventData.backgroundImage}
        heading={eventData.heading}
        description={eventData.description}
      />
      <PictureTextCard 
        image={newsData[0].image}
        name={newsData[0].name}
        description={newsData[0].description}
        link={newsData[0].link}
      />
      <PictureTextCard 
        image={newsData[1].image}
        name={newsData[1].name}
        description={newsData[1].description}
        link={newsData[1].link}
      />
      <PictureTextCard 
        image={newsData[2].image}
        name={newsData[2].name}
        description={newsData[2].description}
        link={newsData[2].link}
      />
    </Box>
  );
}

export default News;

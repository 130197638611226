// SeasonDiscover.js
import React from 'react';
import { Box, Typography, Grid } from '@mui/material';
import ReactPlayer from 'react-player';
import MUIButton from './MUIButton';

const SeasonDiscover = ({ videoUrl, content, payOnlineLink, payByChequesLink, downloadBrochureLink}) => {
  return (
    <Box sx={{ backgroundColor: '#F1F1F1', width: '100%', py: 1 }}>
      <Box sx={{ my: 5, mx: 'auto', maxWidth: '1200px', px: 3 }}>
        <Typography variant="h4" component="h2" gutterBottom>
          Discover the Season
        </Typography>
        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            <Box 
              sx={{ 
                position: 'relative', 
                paddingTop: '56.25%', 
                borderRadius: '4px',
                overflow: 'hidden',
              }}
            >
              <img  
                src={videoUrl} 
                width="100%"
                height="100%"
                style={{ position: 'absolute', top: 0, left: 0 }}
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="body1" paragraph>
              {content[0]}
            </Typography>
            <Box sx={{ my: 2, display: 'flex', justifyContent: 'center' }}>
            <a href={downloadBrochureLink} target="_blank" style={{ textDecoration: 'none' } }>
              <MUIButton variant="tertiary">
                Download the Season Brochure
              </MUIButton>
            </a>
            </Box>
            <Typography variant="body1" paragraph>
              {content[1]}
            </Typography>
            <Box sx={{ my: 2, display: 'flex', justifyContent: 'center', gap: 2 }}>
            <a href={payOnlineLink} target="_blank" style={{ textDecoration: 'none' } }>
              <MUIButton variant="tertiary">
                Pay Online 
              </MUIButton>
            </a>
            <a href={payByChequesLink} target="_blank" style={{ textDecoration: 'none' } }>
              <MUIButton variant="tertiary">
                Pay by Cheques
              </MUIButton>
            </a>
            </Box>
            <Typography variant="body1" paragraph>
              {content[2]}
            </Typography>
            <Typography variant="body1" paragraph>
              {content[3]}
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default SeasonDiscover;

import React from "react";
import '../../App.css';
import HeroSectionEventCard from '../HeroSectionEventCard';
import MUICard5 from '../MUICard5'; 
import {Box} from '@mui/material';

function BarrieConcerts() {

    const eventData = {
        backgroundImage: '/images/bg-1.jpg',
        heading: 'Barrie Concerts',
        description: 'Get the best seats at the best price when you buy a subscription ticket package. Learn more about the benefits of being a BCA subscriber and available ticket package options.'
    };

    const events = [
        {
            image: 'images/Barrie-Concerts/2024-2025/bc_05-10-24.jpg', 
            title: 'Duo Turgeon',
            date: 'Saturday 19 October 2024 | 7:30 pm',
            description: 'From Bruce with Love!',
            bookTicketLink:'https://www.showpass.com/barrie-concerts-sat-series-2024-2025/',
            learnMoreLink:'/SingleEvent6'
        },
        {
            image: 'images/Barrie-Concerts/2024-2025/bc_30-11-24.jpg',
            title: 'Dévah Quintet',
            date: 'Saturday 30 November 2024 | 7:30 pm',
            description: 'Dévah Quintet',
            bookTicketLink:'https://www.showpass.com/barrie-concerts-sat-series-2024-2025/',
            learnMoreLink:'/SingleEvent7'
        },
        {
            image: 'images/Barrie-Concerts/2024-2025/bc_18_01_25.jpg',
            title: 'True North Brass',
            date: 'Saturday 18 January 2025 | 7:30 pm',
            description: 'Dancing Across the Global',
            bookTicketLink:'https://www.showpass.com/barrie-concerts-sat-series-2024-2025/',
            learnMoreLink:'/SingleEvent8'
        },
        {
            image: 'images/Barrie-Concerts/2024-2025/bc_22_02_25.jpg', 
            title: 'Ensemble Caprice',
            date: 'Saturday 22 February 2025 | 7:30 pm',
            description: 'Vivaldi on Fire',
            bookTicketLink:'https://www.showpass.com/barrie-concerts-sat-series-2024-2025/',
            learnMoreLink:'/SingleEvent9'
        },
        {
            image: 'images/Barrie-Concerts/2024-2025/bc_29_03_25.jpg',
            title: 'Francine Kay',
            date: 'Saturday 29 March 2025 | 7:30 pm',
            description: 'Things Lived and Dreamt',
            bookTicketLink:'https://www.showpass.com/barrie-concerts-sat-series-2024-2025/',
            learnMoreLink:'/SingleEvent10'
        },
        {
            image: 'images/Barrie-Concerts/2024-2025/bc_26_04_25.jpg',
            title: 'Toronto Concert Orchestra',
            date: 'Saturday 26 April 2025 | 7:30 pm',
            description: 'Marcus Scholtz conducting',
            bookTicketLink:'https://www.showpass.com/barrie-concerts-sat-series-2024-2025/',
            learnMoreLink:'/SingleEvent11'
        },
    ];

    return (
        <Box sx={{ backgroundColor: '#F1F1F1' }}>
            <HeroSectionEventCard
                backgroundImage={eventData.backgroundImage}
                heading={eventData.heading}
                description={eventData.description}
            />
            <Box sx={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap', gap: '10px', padding: '10px' }}>
                {events.map((event, index) => (
                    <MUICard5 key={index} event={event} />
                ))}
            </Box>
        </Box>
    );
}

export default BarrieConcerts;

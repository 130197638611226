import React from 'react';
import { Box, Typography, Card, CardContent, CardMedia } from '@mui/material';

const FounderCard = ({ image, name, description }) => {
  return (
    <Card sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '20px', marginX: '30px'}}>
      <CardMedia
        component="img"
        sx={{ width: 250, height: 250, borderRadius: '50%' }}
        image={image}
        alt={name}
      />
      <CardContent sx={{ paddingLeft: '30px' }}>
        <Typography component="div" variant="h5" gutterBottom>
          {name}
        </Typography>
        <Typography variant="body1" color="text.secondary" component="div">
          {description}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default FounderCard;

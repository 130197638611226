import React from "react";
import '../../App.css';
import HeroSectionEventCard from '../HeroSectionEventCard';
import MUICard5 from '../MUICard5'; 
import {Box} from '@mui/material';

function GeorgianMusic() {

    const eventData = {
        backgroundImage: '/images/bg-1.jpg',
        heading: 'Georgian Music',
        description: 'Get the best seats at the best price when you buy a subscription ticket package. Learn more about the benefits of being a BCA subscriber and available ticket package options.'
    };

    const events = [
        {
            image: 'images/Georgian-Music/2024-2025/gm_15-09-24.jpg', 
            title: 'Andromeda Trio',
            date: 'Sunday 15 September 2024 | 3:00 pm',
            description: 'Tchaikovsky, Shostakovich, Haydn',
            bookTicketLink:'https://www.showpass.com/andromeda-trio/',
            learnMoreLink:'/SingleEvent'
        },
        {
            image: 'images/Georgian-Music/2024-2025/gm_03-11-24.jpg',
            title: 'Ishay Shaer',
            date: 'Sunday 03 November 2024 | 3:00 pm',
            description: 'Bach, Beethoven, Debussy, Schumann',
            bookTicketLink:'https://www.showpass.com/bca-georgian-music-sun-series-2024-2025/',
            learnMoreLink:'/SingleEvent2'
        },
        {
            image: 'images/Georgian-Music/2024-2025/gm_15-12-24.jpg',
            title: 'Venuti Quartet',
            date: 'Sunday 15 December 2025 | 3:00 pm',
            description: 'Indigo',
            bookTicketLink:'https://www.showpass.com/bca-georgian-music-sun-series-2024-2025/',
            learnMoreLink:'/SingleEvent3'
        },
        {
            image: 'images/Georgian-Music/2024-2025/gm_02-02-25.jpg', 
            title: 'Hall & Farley',
            date: 'Sunday 02 February 2025 | 3:00 pm',
            description: 'A Nightingale Sang',
            bookTicketLink:'https://www.showpass.com/bca-georgian-music-sun-series-2024-2025/',
            learnMoreLink:'/SingleEvent4'
        },
        {
            image: 'images/Georgian-Music/2024-2025/gm_13-04-25.jpg',
            title: 'Piano Duo',
            date: 'Sunday 13 April 2025 | 3:00 pm',
            description: 'The World of Yesterday',
            bookTicketLink:'https://www.showpass.com/bca-georgian-music-sun-series-2024-2025/',
            learnMoreLink:'/SingleEvent5'
        },
    ];

    return (
        <Box sx={{ backgroundColor: '#F1F1F1' }}>
            <HeroSectionEventCard
                backgroundImage={eventData.backgroundImage}
                heading={eventData.heading}
                description={eventData.description}
            />
            <Box sx={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap', gap: '10px', padding: '10px' }}>
                {events.map((event, index) => (
                    <MUICard5 key={index} event={event} />
                ))}
            </Box>
        </Box>
    );
}
export default GeorgianMusic;

// ArticleCard.js
import React from 'react';
import { Card, CardContent, Typography, Box, Divider } from '@mui/material';

const ArticleCard = ({program, performers, ticketInfo, performerLink}) => {
  return (
    <Box display="flex" justifyContent="center" alignItems="center" bgcolor="#f5f5f5">
      <Card sx={{ width: '100%', bgcolor: '#e0e0e0', padding: '20px', position: 'relative' }}>
        <CardContent>
          {/* <Typography variant="h5">{title}</Typography>
          <Typography variant="body2" sx={{ marginRight: '50%' }}>
            {subtitle}
          </Typography>
          <Typography variant="body2" sx={{ marginRight: '50%' }}>
            {date}
          </Typography>
          <Typography variant="body2" sx={{ marginRight: '50%' }}>
            {location}
          </Typography> */}
          <Box sx={{ mt: 2}}>
            <Typography variant="h5">The Program</Typography>
            <Typography variant="body2">
              {program}
            </Typography>
          </Box>
          <Divider sx={{ my: 2, bgcolor: 'grey.500' }} />
          <Box mt={2}>
            <Typography variant="h5">The Performers</Typography>
            <Typography variant="body2">
              {performers}
            </Typography>
            <Typography variant="body2">
              <a href={performerLink} target="_blank" >Click here to visit Performer's Website</a>
            </Typography>
          </Box>
          <Divider sx={{ my: 2, bgcolor: 'grey.500' }} />
          <Box mt={2}>
            <Typography variant="h5">Ticket information</Typography>
            <Typography variant="body2">
              {ticketInfo}
            </Typography>
          </Box>
        </CardContent>
        {/* Floating Card with White Background
        <Card
          sx={{
            position: 'absolute',
            top: '20px',
            right: '20px',
            width: '45%',
            bgcolor: 'white',
            boxShadow: 3,
            zIndex: 1,
            padding: '20px',
          }}
        >
          <CardContent>
            <Typography variant="h5">The Performance</Typography>
            <Typography variant="body2">
              {performance}
            </Typography>
          </CardContent>
        </Card> */}
      </Card>
    </Box>
  );
};

export default ArticleCard;

import React from 'react';
import { Card, CardContent, Typography, Box } from '@mui/material';
import MUIButton from './MUIButton';

const MUISingleEventCard = ({ image, title, subtitle, date, location, buttonText, buttonLink, sx }) => {
  return (
    <Box display="flex" justifyContent="center" alignItems="center" sx={{ padding: 0, margin: 0, width: '100%' }}>
      <Card
        sx={{
          ...sx,
          width: '100%',
          height: '100vh',
          position: 'relative',
          backgroundImage: `url(${image})`,
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          margin: 0,
          padding: 0,
          paddingTop: '64px' 
        }}
      >
        <CardContent sx={{ color: 'white', textShadow: '1px 1px 2px rgba(0,0,0,0.8)', padding: '16px', position: 'absolute', bottom: 0, left: 0, textAlign: 'left', marginLeft: '20px' }}>
          <Typography variant="h2">{title}</Typography>
          <Typography variant="h3">{subtitle}</Typography>
          <Typography variant="h5">{location}</Typography>
          <Typography variant="h5">{date}</Typography>
          <a href={buttonLink} target="_blank" style={{ textDecoration: 'none' }}>
            <MUIButton variant="tertiary" sx={{ mt: 2, padding: '20px 200px', fontSize: '24px', opacity: 0.8}}>{buttonText}</MUIButton>
          </a>
        </CardContent>
      </Card>
    </Box>
  );
};

export default MUISingleEventCard;

import React from 'react';
import { Typography, Box } from '@mui/material';
import MUIButton from './MUIButton';

function MUICard3() {
  return (
    <Box
      sx={{
        backgroundImage: 'url(images/bca-story.jpg)',
        color: '#fff',
        height: '300px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginX: '50px'
      }}
    >
      <Box
        sx={{
          textAlign: 'center',
          display: 'flex',
          flexDirection: 'column'
        }}
      >
        <Typography
          variant="h1"
          sx={{
            fontSize: '2em',
            marginBottom: '20px',
          }}
        >
          Save 40% on 2024-2025 Concerts with Our Subscription Packages!
        </Typography>
        <Typography
          variant="p3"
          sx={{
            fontSize: '1em',
            marginBottom: '20px',
          }}
        >
          Get your 2024-2025 subscription now and enjoy concerts at nearly 40% off compared to single ticket prices!
        </Typography>
     <a href='https://www.showpass.com/barrie-concerts-and-georgian-music-2024-2025/' target='_blank'>
        <MUIButton
          variant="tertiary"
          sx={{ backgroundColor: '#fff', padding: '10px 20px' }}
        >
          Explore
        </MUIButton>
     </a>
       
      </Box>
    </Box>
  );
}

export default MUICard3;
